<template>
    <div class="payment">
        <!-- 表单弹窗 -->
        <div class="from">
            <el-dialog title="查看评论" :visible.sync="payDialog" :closeOnClickModal='false' width="1200px" @close="closeDialog()">
                <div class="tab">
                    <div class="duoshao" style="margin-top:10px ;margin-bottom:0px">
                        <div class="title">文章列表</div>
                        <div class="lvtotal" style="line-height: 33px;">总条数：{{lvtotal}} 条
                        </div>
                    </div>
                </div>
                <el-table ref="filterTable" :data="teacherList" style="width: 100%;">
                    <el-table-column prop="id" label="ID" width="60px">
                    </el-table-column>
                    <el-table-column prop="created_at" label="回复时间">
                    </el-table-column>
                    <el-table-column prop="postReply" label="回复内容" width="440">
                    </el-table-column>
                    <el-table-column prop="post_status" label="问答状态" width="140px">
                        <template slot-scope="scope">
                            <el-dropdown>
                                <div class="el-dropdown-link">
                                    <span v-if="scope.row.is_show==1" style="background: #e6a23c; padding:8px;border-radius: 5px;">未审核</span>
                                    <span v-if="scope.row.is_show==2" style="background: #67c23a;  padding:8px;border-radius: 5px;">审核通过</span>
                                </div>
                                <el-dropdown-menu slot="dropdown" placement="bottom" trigger="click" popper-class="class-menu">
                                    <el-dropdown-item @click.native='star(1,scope.row)'><span>未审核</span></el-dropdown-item>
                                    <el-dropdown-item @click.native='star(2,scope.row)'><span>审核通过</span></el-dropdown-item>
                                </el-dropdown-menu>
                            </el-dropdown>
                        </template>
                    </el-table-column>
                    <el-table-column prop="step_num" label="踩人数" width="100px">
                    </el-table-column>
                    <el-table-column prop="like_num" label="点赞数" width="100px">
                    </el-table-column>
                    <el-table-column prop="is_best" label="是否为最佳评论" width="100px">
                    </el-table-column>
                </el-table>
                <div slot="footer" class="dialog-footer">
                    <el-button @click="closeDialog" class="success button">取 消</el-button>
                    <el-button type="primary" @click="closeDialog" class="tijiao primary button">确 定</el-button>
                </div>
            </el-dialog>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            payDialog: false, //操控弹窗展示
            teacherList: [],
            message_id: ""
        };
    },
    mounted() { },
    components: {
    },
    methods: {
        // 点击退款
        // onrefund(data) {
        //     this.$refs.refund.onyunyingtan(this.id);
        // },
        ongetWebsitePostMessageList() {
            var param = {
                message_id: this.message_id,
            };
            this.$service.get(this.$api.getWebsitePostMessageList, param, (res) => {
                if (res.code == "200") {
                    this.teacherList = res.data;
                }
            });
        },
        answer(data) {
            this.payDialog = true;
            this.message_id = data.id;
            this.ongetWebsitePostMessageList()
        },
        star(val, row) {
            var param = {
                is_show: val,
                id: row.id,
            };
            this.$service.post(this.$api.setPostMessageStatus, param, (res) => {
                if (res.code == "200") {
                    this.ongetWebsitePostMessageList();
                }
            });
        },
        parent() {
            this.payDialog = false;
            this.$parent.ongetInternalOrderList();
        },

        closeDialog() {
            // this.dubber="";
            this.payDialog = false;
        },
        // 提交编辑
        tijiao() {
            // var param = {
            //    order_id:this.order_id,
            //    mixer_id:this.dubber
            // }
            // if (param.mixer_id == "") {
            //     this.$message({ message: "请选择配音师", type: "warning" });
            //     return;
            // }
            // this.$service.post(this.$api.alterDubOrderMixer, param, (res) => {
            //     if (res.code == "200") {
            //         this.$message({
            //             message: "修改成功",
            //             type: "success",
            //         });
            //         this.yunyingtan = false;
            //         this.dubber="";
            //         this.$parent.ongetInternalOrderList();
            //     }
            // });
        },
    },
};
</script>

<style lang="scss" scoped>
.payment {
    .chuangjian {
        // height: 40px;
        line-height: 40px;
    }
    .xing {
        color: red;
        margin-right: 6px;
    }
    .kuai {
        width: 200px;
        text-align: center;
        display: inline-block;
        font-weight: bold;
    }
    .tijiao {
        margin-left: 40px;
    }
    // .button {
    //     width: 90px;
    //     color: #ffffff;
    // }
    .img {
        width: 148px;
        height: 148px;
        margin-right: 20px;
    }
    //
}
</style>
<style lang="scss">
.payment {
    .upload-demo {
        display: inline-block;
    }
    .el-upload-dragger {
        height: 150px;
    }
    .el-upload__tip {
        width: 250px;
    }
    .el-dialog__header {
        background: #0075f7;
    }
    .el-dialog__title {
        color: #ffffff;
    }
    .el-form-item__content {
        display: flex;
    }
    .el-radio {
        line-height: 40px;
    }
    .right {
        // display: flex;
    }
}
</style>