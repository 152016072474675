<template>
    <div class="author">
        <!--  -->
        <div class="head padd20 borbut20">
            <div class="topSearchSou">
                <div class="searchdan">
                    <el-input v-model="article_title" placeholder="请输入标题名称" style="margin-right:25px"></el-input>
                    <el-date-picker v-model="date" type="daterange" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期">
                    </el-date-picker>
                </div>
                <el-button type="primary" icon="el-icon-search" @click="onButtonInquire()">搜索</el-button>
                <!-- <el-button class="Shuarefresh" @click="$router.go(0)" icon='el-icon-refresh-right'>重置数据</el-button> -->
            </div>
        </div>
        <!--  -->
        <div class="tab padd20">
            <div class="duoshao">
                <div class="title">文章列表</div>
                <el-button type="primary" @click="batches()" class="yinyinga" icon="el-icon-delete">批量删除</el-button>
                <div class="lvtotal" style="line-height: 33px;">总条数：{{lvtotal}} 条
                    <span style="margin-left:20px">每页显示:</span>
                    <el-select v-model="per_page" placeholder="10" @change="selectTrigger()" style="width:80px; margin:0px 15px">
                        <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
                        </el-option>
                    </el-select>
                    条
                </div>
            </div>
            <el-table ref="filterTable" :data="tableData" style="width: 100%" @selection-change="handleSelectionChange">
                <el-table-column type="selection" width="40">
                </el-table-column>
                <el-table-column prop="id" label="ID" width="60px">
                </el-table-column>
                <el-table-column prop="post_title" label="帖子标题">
                </el-table-column>
                <el-table-column prop="post_desc" label="帖子内容">
                </el-table-column>
                <el-table-column prop="post_status" label="问答状态" width="140px">
                    <template slot-scope="scope">
                        <el-dropdown>
                            <div class="el-dropdown-link">
                                <span v-if="scope.row.post_status==1" style="background: #e6a23c; padding:8px;border-radius: 5px;">未审核</span>
                                <span v-if="scope.row.post_status==2" style="background: #67c23a;  padding:8px;border-radius: 5px;">审核通过</span>
                            </div>
                            <el-dropdown-menu slot="dropdown" placement="bottom" trigger="click" popper-class="class-menu">
                                <el-dropdown-item @click.native='star(1,scope.row)'><span>未审核</span></el-dropdown-item>
                                <el-dropdown-item @click.native='star(2,scope.row)'><span>审核通过</span></el-dropdown-item>
                            </el-dropdown-menu>
                        </el-dropdown>
                    </template>
                </el-table-column>
                <el-table-column prop="post_class" label="问答类型" width="140px">
                    <template slot-scope="scope">
                        <div>
                            <el-select v-model="scope.row.post_class" placeholder="请选择" @change="onarticle(scope.row.post_class,scope.row.id)">
                                <el-option v-for="item in type_List" :key="item.value" :label="item.label" :value="item.value">
                                </el-option>
                            </el-select>
                        </div>
                    </template>
                </el-table-column>
                <el-table-column prop="read_num" label="阅读数" width="100px">
                </el-table-column>
                <el-table-column prop="like_num" label="点赞数" width="100px">
                </el-table-column>
                <el-table-column prop="reply_num" label="回复数" width="100px">
                </el-table-column>

                <el-table-column label="操作" width="160">
                    <template slot-scope="scope">
                        <el-button type="primary" class="bianji" size="mini" plain @click="handleEdit(scope.$index, scope.row ,$event) " id="ComAccount">查看评论</el-button>
                        <!-- <el-button type="primary" class="bianji" size="mini" plain @click="onCase(scope.$index, scope.row ,$event) " style="margin-left:20px" id="ComAccount">查看案例</el-button> -->
                        <!-- <el-popconfirm title="确定要删除本条记录吗" @confirm="onEnter(scope.row)">
                            <el-button size="mini" slot="reference" type="danger">删除</el-button>
                        </el-popconfirm> -->
                    </template>
                </el-table-column>
            </el-table>
            <!-- 分页 -->
            <div class="fenye">
                <el-pagination class="lv-page" :page-size="per_page" :pager-count="11" @current-change="DialogCurrentChange" background layout="prev, pager, next, jumper" :total="lvtotal" hide-on-single-page :current-page.sync="paramPage"></el-pagination>
            </div>
            <Comment ref="Comment"></Comment>
            <!-- <EditAuthor ref="EditAuthor"></EditAuthor> -->
            <!-- 
            <EditTeacher ref="EditTeacher"></EditTeacher>
            <Case ref="Case"></Case> -->

        </div>
    </div>
</template>
 
<script>
import Comment from "./comment.vue";
// import EditAuthor from "./EditAuthor.vue";
// import Case from "./case.vue";
// import EditTeacher from "./EditTeacher.vue";
export default {
    data() {
        return {
            paramData: {}, //表格参数
            dialogFormVisible: false,
            voice: "", //搜索内容
            tableData: [],
            per_page: 10, //一页多少条
            lvtotal: null, //共几条数据
            paramPage: 1, //表格当前第几页
            page: "1",
            activeName: "",
            PageTopCompanyList: 1, //群页码
            company_id: "", //群id
            qunlist: "", //群循环
            PageTopCorporateAccountList: 1, //企业页码
            corporate_account_id: "", //企业id
            qiyelist: "", //企业循环
            accountlist: "", //端口列表
            PagegetAdvertiserList: "", //端口页码
            teacher_id: "",
            audio: "",
            options: [
                {
                    value: 25,
                    label: "25",
                },
                {
                    value: 50,
                    label: "50",
                },
                {
                    value: 100,
                    label: "100",
                },
            ],
            type_List: [{
                value: '1',
                label: '巨量千川'
            }, {
                value: '2',
                label: '抖店'
            }, {
                value: '3',
                label: '二类电商'
            }],
            date: "",
            search_start_date: "",
            search_end_date: "",
            TypeList: [],
            article_title: "",
            switch: "",
            arr_id: []
        };
    },
    mounted() {
        this.getWebsitePostList();
        // this.getWebsiteTypeList()
    },
    components: {
        Comment,
        // EditAuthor,
    },
    methods: {
        // 批量删除
        batches() {
            var param = {
                id: this.arr_id,
            };
            this.$service.post(this.$api.batchRemove, param, (res) => {
                if (res.code == "200") {
                    this.getWebsitePostList();
                }
            });
        },
        //多选
        handleSelectionChange(val) {
            var arr = [];
            val.forEach((v, k) => {
                arr.push(v.id);
            });
            this.arr_id = arr;
        },
        onarticle(val, id) {
            var param = {
                post_class: val,
                id: id,
            };
            this.$service.post(this.$api.setPostClass, param, (res) => {
                if (res.code == "200") {
                    this.getWebsitePostList();
                }
            });
        },
        //资历
        qualifications(val, row) {
            var param = {
                old: val,
                mixer_id: row.id,
            };
            this.$service.post(this.$api.setMixerOld, param, (res) => {
                if (res.code == "200") {
                    this.getWebsitePostList();
                }
            });
        },
        // 播放录音
        onrecord(row) {
            this.audio = row.file_introduce.file_url;
            this.dialogFormVisible = true;
        },
        closeDialog() {
            this.audio = "";
            this.dialogFormVisible = false;
        },
        //搜索
        onButtonInquire() {
            if (this.date != "") {
                this.search_start_date = this.dateFtt("yyyy-MM-dd", this.date[0]) ? this.dateFtt("yyyy-MM-dd", this.date[0]) : "";
                this.search_end_date = this.dateFtt("yyyy-MM-dd", this.date[1]) ? this.dateFtt("yyyy-MM-dd", this.date[1]) : "";
            }
            this.paramData = {
                start_time: this.search_start_date,
                end_time: this.search_end_date,
                post_title: this.article_title
            };
            this.paramPage = 1;
            this.getWebsitePostList();
        },
        //排序
        oninput(val, row) {
            var param = {
                mixer_id: row.id,
                sorf: val,
            };
            this.$service.post(this.$api.setMixerSorf, param, (res) => {
                if (res.code == "200") {
                    this.getWebsitePostList();
                }
            });
        },
        //新增配音师
        addbud(e) {
            this.$refs.AddAuthor.onyunyingtan();
        },
        //查看案例
        onCase(index, row, e) {
            this.$router.push({
                path: "/ErLangCha/teacher/case",
                query: { id: row.id },
            });
            // this.$refs.Case.ontickling(row.id);
        },
        //配音师列表
        getWebsitePostList() {
            this.tableData = [];
            var param = this.paramData;
            param.page = this.paramPage;
            this.$service.get(this.$api.getWebsitePostList, param, (res) => {
                if (res.code == "200") {
                    console.log(res.data)
                    this.tableData = res.data.data;
                    this.lvtotal = res.data.total;
                }
            });
        },
        //显示条数改变
        selectTrigger() {
            this.paramPage = 1;
            this.paramData.per_page = this.per_page;
            this.getWebsitePostList();
        },
        // 分页
        DialogCurrentChange(val) {
            this.paramPage = val;
            this.getWebsitePostList();
        },
        //设置显示
        star(val, row) {
            var param = {
                post_status: val,
                id: row.id,
            };
            this.$service.post(this.$api.setPostStatus, param, (res) => {
                if (res.code == "200") {
                    this.getWebsitePostList();
                }
            });
        },
        // 筛选
        filterTag(value, row) {
            return row.tag === value;
        },
        // 编辑
        handleEdit(index, row, e) {
            this.$refs.Comment.answer(row);
        },
        //删除
        onEnter(row) {
            var param = {
                id: row.id,
            };
            this.$service.post(this.$api.articleDel, param, (res) => {
                if (res.code == "200") {
                    this.getWebsitePostList();
                }
            });
        },
        //启用
        onEnable(val, row) {
            var param = {
                status: val,
                mixer_id: row.id,
            };
            this.$service.post(this.$api.setMixerStatus, param, (res) => {
                if (res.code == "200") {
                    this.getWebsitePostList();
                }
            });
        },
        //禁用
        onForbidden(val, row) {
            var param = {
                status: val,
                mixer_id: row.id,
            };
            this.$service.post(this.$api.setMixerStatus, param, (res) => {
                if (res.code == "200") {
                    this.getWebsitePostList();
                }
            });
        },
    },
};
</script>

<style lang="scss" scoped>
.author {
    display: flex;
    flex-direction: column; //垂直显示
    .head {
        display: flex;
        justify-content: center;
    }

    .tab {
        img {
            width: 40px;
            height: 40px;
        }
    }
    .fenye {
        text-align: right;
        margin-top: 25px;
        margin-right: 50px;
    }
    .xzyunying {
        margin-bottom: 20px;
    }
    .ii {
        width: 20px;
        height: 20px;
    }
    .iconfont {
        // color: red;
        // margin-top: 24px;
    }
    .red {
        color: red;
    }
    .dialog {
        margin-top: 200px;
    }
    .audio {
        height: 100px;
        padding: 40px 0px;
    }
    .sorfinput {
    }
}
</style>
<style lang="scss">
.author {
    // .el-input {
    //     width: 180px;
    //     margin-right: 15px;
    // }
    .el-step__title {
        margin-left: -5px;
    }

    .el-table__column-filter-trigger i {
        color: #0075f7;
        margin-left: 10px;
    }
    .el-form-item__label {
        text-align: left;
    }
    .el-form-item {
        margin-top: 20px;
    }
    .el-form-item__content {
        margin-left: 0 !important;
    }
    .dialog-footer {
        display: flex;
        justify-content: center;
    }
    .el-dialog__header {
        padding: 20px 20px;
    }
    .el-dropdown-link {
        cursor: pointer;
        color: #fff;
        padding: 5px;
        position: relative;
    }
    .tag {
        cursor: pointer;
    }
    .el-dialog__header {
        background: #0075f7 !important;
    }
    .el-dialog__title {
        color: #fff;
    }
    .is-in-pagination {
        width: 50px;
        margin-left: 10px;
        margin-right: 10px;
        text-align: center !important;
    }
    .el-pagination__jump .el-input__inner {
        text-align: center !important;
    }
    .el-date-editor .el-range-separator {
        width: 10% !important;
    }
}
</style>